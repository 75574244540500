<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row class="form-container rounded-lg">
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลพาร์ทเนอร์
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col cols="12">
          <image-uploader
            v-model="images"
            :max-file="1"
            square
            :disabled="loading" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="formData.name"
            label="ชื่อพาร์ทเนอร์"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            required />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import PartnersProvider from '@/resources/partners.provider'
import UploaderProvider from '@/resources/uploader.provider'

const PartnersService = new PartnersProvider()
const UploaderService = new UploaderProvider()

export default {
  components: {
    ImageUploader
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    formData: {
      imageUrl: '',
      name: ''
    },
    images: []
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    }
  },
  mounted () {
    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'SettingsPartner' })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await PartnersService.getItemById(this.$route.params.id)

        this.formData = { ...data }
        this.images = data?.imageUrl ? [{
          file: null,
          preview: data.imageUrl,
          mediaUrl: data.imageUrl
        }] : []
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        let imageUrl = this.images[0]?.mediaUrl || ''

        if (this.images[0]?.file) {
          const { data: image } = await UploaderService.uploadFile(this.images[0].file)

          imageUrl = image?.publicUrl
        }

        if (!imageUrl) {
          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'โปรดอัพโหลดรูปอย่างน้อย 1 รูป',
            type: 'error',
            timeout: 2000
          })

          this.loading = false
          return
        }

        if (!this.isEdit) {
          await PartnersService.create({
            ...this.formData,
            imageUrl
          })
        } else {
          await PartnersService.updateItemById({
            ...this.formData,
            imageUrl
          })
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'SettingsPartner' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
