import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class PartnersProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/partners', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/partners/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/partners', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/partners/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/partners/${id}`)
  }
}

export default PartnersProvider
